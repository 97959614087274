import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Sidenav from 'examples/Sidenav';
import theme from 'assets/theme';
import routes from 'routes';
import { useMaterialUIController, setMiniSidenav } from 'context';
import brandWhite from 'assets/images/logo.png';
import { DASHBOARD, LOGIN } from 'constants/routes';
import Login from 'pages/authentication/login';
import Dashboard from 'pages/dashboard';
import AuthContext from 'context/AuthContext';
import { RESULTS } from 'constants/routes';
import Results from 'pages/results';
import { PAYMENTS } from 'constants/routes';
import Payments from 'pages/payments';
import { PROFILE } from 'constants/routes';
import Profile from 'pages/profile';
import { INVOICES } from 'constants/routes';
import Invoices from 'pages/invoices';
import { INVOICE_DETAILS } from 'constants/routes';
import InvoiceDetails from 'pages/invoices/details';
import { ERROR_401 } from 'constants/routes';
import UnauthorizedPage from 'pages/Errors/UnauthorizedPage';
import { ERROR_404 } from 'constants/routes';
import NotFoundPage from 'pages/Errors/NotFoundPage';

const ProtectedRoute = () => {
  const context = useContext(AuthContext);
  return context.isLoggedIn ? <Outlet /> : <Navigate to={LOGIN} />;
};

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const context = useContext(AuthContext);
  const { miniSidenav, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === 'dashboard' && context.isLoggedIn && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brandWhite}
            brandName="NG'ONA TRUST"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        <Route exact path={LOGIN} element={<Login />} />
        <Route exact path={ERROR_401} element={<UnauthorizedPage />} />
        <Route exact path={ERROR_404} element={<NotFoundPage />} />
        <Route exact path={DASHBOARD} element={<ProtectedRoute />}>
          <Route exact path={DASHBOARD} element={<Dashboard />} />
        </Route>
        <Route exact path={RESULTS} element={<ProtectedRoute />}>
          <Route exact path={RESULTS} element={<Results />} />
        </Route>
        <Route exact path={INVOICES} element={<ProtectedRoute />}>
          <Route exact path={INVOICES} element={<Invoices />} />
        </Route>
        <Route exact path={INVOICE_DETAILS} element={<ProtectedRoute />}>
          <Route exact path={INVOICE_DETAILS} element={<InvoiceDetails />} />
        </Route>
        <Route exact path={PAYMENTS} element={<ProtectedRoute />}>
          <Route exact path={PAYMENTS} element={<Payments />} />
        </Route>
        <Route exact path={PROFILE} element={<ProtectedRoute />}>
          <Route exact path={PROFILE} element={<Profile />} />
        </Route>
        <Route path="*" element={<Navigate to={DASHBOARD} />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
